import React from "react";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";

class Radio extends React.Component {
  render() {
    return (
      <div className="audio-payer">
        <AudioPlayer
          src="https://sivanfm-unicast2u.radioca.st/stream"
          controls="true"
          autoPlay="true"
          ref={(a) => (this._audioo = a)}

          // other props here
        />
      </div>
    );
  }
}
export default Radio;
